<script setup lang="ts">
import { MenuItem } from "@headlessui/vue";

interface Props {
  href?: string;
  icon?: string;
}

interface Emit {
  (e: "click"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const onClick = () => {
  if (props.href) return navigateTo(props.href);
  emit("click");
};
</script>

<template>
  <MenuItem v-slot="{ active }">
    <div @click="onClick" class="flex items-center group hover:text-primary text-dark-gray gap-2 px-3 py-1 text-sm leading-6 rounded-md cursor-pointer" :class="[active ? 'bg-gray-100' : '']">
      <Icon v-if="icon" :name="icon" class="text-medium-gray group-hover:text-primary"/>
      <slot />
    </div>
  </MenuItem>
</template>
