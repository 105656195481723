<script setup lang="ts">
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

interface Props {
  buttonClass?: string;
  open?: "up" | "down" | "left" | "right";
}

const props = withDefaults(defineProps<Props>(), {
  open: "down",
});

const openDirection = computed(() => {
  switch (props.open) {
    case "up":
      return "origin-bottom right-0 bottom-full mb-2.5";
    case "down":
      return "origin-top right-0 top-full mt-2.5";
    case "left":
      return "origin-top-right right-full mr-2.5";
    case "right":
      return "origin-top-left left-full ml-2.5";
  }
});
</script>

<template>
  <Menu as="div" class="relative" v-slot="{ open }">
    <MenuButton :class="buttonClass ?? '-m-1.5 flex items-center p-1 outline-none focus:outline-none rounded-md'">
      <slot name="dropdown-button" :open="open" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute z-10 w-44 rounded-md bg-white p-1 shadow-lg ring-1 ring-black-gray/5 focus:outline-none"
        :class="openDirection"
      >
        <slot name="dropdown-items" />
      </MenuItems>
    </transition>
  </Menu>
</template>
